import React from 'react'
import styled from 'styled-components'

const Container1440 = ({ children, ...props }) => {
    return (
        <Container {...props}>
            {children}
        </Container>
    )
}

export default Container1440

const Container = styled.div`
display:flex;
align-items:center;
margin-left:auto;
margin-right:auto;
max-width:1440px;
width:100%;
`