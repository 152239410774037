import React from 'react'
import PhoneInputWithCountrySelect from 'react-phone-number-input'
import styled from 'styled-components'
import { colors } from '../../Assets/colors/colors'
import 'react-phone-number-input/style.css'

const CustomPhoneInput = ({ value, setValue, defaultCountry }) => {

    return (
        <Conatiner>
            <PhoneInputWithCountrySelect
                international
                defaultCountry={defaultCountry}
                placeholder="Enter phone number"
                value={value}
                onChange={setValue}
            />
        </Conatiner>
    )
}

export default CustomPhoneInput
const Conatiner = styled.div`
input{
    border-radius:10px;
    border:2px solid ${colors.headerBorderColor};
    padding:8px 15px;
    font-size:16px;
    color:${colors.darkColor};
    font-family:"Sailec-Regular";
    line-height:20px;
    outline:none;
}

.PhoneInputCountry{
    border:2px solid ${colors.headerBorderColor};
    padding:8px 15px;
    border-radius:15px;    
    objet-fit:cover;
}
.PhoneInputCountryIcon{
    border-radius:20px;
    overflow:hidden;
    border:none;
    box-shadow:none;
    height:20px;
    width:20px;

img{
object-fit:cover;
}
}
`