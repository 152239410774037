import {
    createContext,
    useState,
    useEffect,
    useContext,
    useCallback,
    useRef,
} from "react";

import { onAuthStateChanged, signInWithPhoneNumber, signOut, updatePhoneNumber } from "firebase/auth";
import { auth } from "../firebase";
import LocalStorage from "../helper/LocalStorage";
import { createUserAPICall, currentUserAPICall, deleteUserAPICall, emailExistAPICall, phoneNumberExistAPICall, updateUserAPICall, } from "../Api/auth/authService";
import { addSubscriptionAPICAll, cancelSubscriptionAPICAll, getSubscriptionAPICAll } from "../Api/auth/subscriptionService";


export const UserContext = createContext({
    user: [],
    userRef: [],
    newTokeId: [],
    currentUser: () => { },
    handleSetUser: () => { },
    createUser: () => { },
    loginUser: () => { },
    logoutUser: () => { },
    fetchCurrentUser: () => { },
    navigateToDashboardIfPossible: () => { },
    signInWithPhone: () => { },
    verifyOTP: () => { },
    authObserver: () => { },
    emailExistInFirebase: () => { },
    phoneNumberExistInFirebase: () => { },
    logout: () => { },
    handleUserResponse: () => { },
    deleteUser: () => { },
    updateUser: () => { },
    addUserSubscription: () => { },
    getUserSubscription: () => { },
    cancelUserSubscription: () => { },
});

const UserContextProvider = ({ children, setLoading }) => {
    const [user, setUser] = useState(undefined);
    const userRef = useRef(null);


    const currentUser = () => {
        return LocalStorage.getCurrentUser();
    };

    const handleSetUser = (user) => {
        setUser(user);
    };

    const fetchCurrentUser = useCallback(async (onSuccess, onError) => {
        currentUserAPICall(function (response) {
            handleUserResponse(response);
            onSuccess(response);
        }, onError);
    });

    const emailExistInFirebase = useCallback(
        async (email, onSuccess, onError) => {
            emailExistAPICall(
                email,
                function (response) {
                    onSuccess(response);
                },
                onError
            );
        }
    );

    const phoneNumberExistInFirebase = useCallback(
        async (phone, onSuccess, onError) => {
            phoneNumberExistAPICall(
                phone,
                function (response) {
                    onSuccess(response);
                },
                onError
            );
        }
    );

    const createUser = useCallback(
        async (name, email, fromWeb, onSuccess, onError) => {
            createUserAPICall(
                name, email, fromWeb,
                function (response) {
                    onSuccess(response);
                },
                onError
            );
        }
    );

    const updateUser = useCallback(
        async (name, email, onSuccess, onError) => {
            updateUserAPICall(
                name, email,
                function (response) {
                    onSuccess(response);
                },
                onError
            );
        }
    );

    const deleteUser = useCallback(
        async (onSuccess, onError) => {
            deleteUserAPICall(
                function (response) {
                    logout();
                    onSuccess(response);
                },
                onError
            );
        }
    );

    const signInWithPhone = (phoneNumber, appVerifier, onSucces, onError) => {
        signInWithPhoneNumber(auth, phoneNumber, appVerifier)
            .then((confirmationResult) => {
                // SMS sent. Prompt user to type the code from the message, then sign the
                // user in with confirmationResult.confirm(code).
                onSucces(confirmationResult);
                // window.confirmationResult = confirmationResult;
                // ...
            })
            .catch((error) => {
                onError(error);
            });
    };

    const verifyOTP = (
        confirmationResult,
        code,
        onSucces,
        onError
    ) => {
        confirmationResult
            .confirm(code)
            .then((result) => {
                const user = result.user;
                onSucces(result);
                // ...
            })
            .catch((error) => {
                onError(error);
                // User couldn't sign in (bad verification code?)
                // ...
            });
    };

    const authObserver = () => {
        onAuthStateChanged(auth, (authUser) => {
            if (authUser) {
                // User is signed in, see docs for a list of available properties
                // https://firebase.google.com/docs/reference/js/auth.user
                const uid = authUser.uid;
                LocalStorage.setAuthenthicationToken(authUser.accessToken);
                LocalStorage.setRefreshToken(authUser.stsTokenManager.refreshToken);
                // console.log("authObserver 1", authUser);
                if (!user) {
                    fetchCurrentUser(
                        function () { },
                        function () { }
                    );
                }
            } else {
                // console.log("authObserver 3");
                handleLogoutUser();
                // User is signed out
                // ...
            }
        });
    };




    const handleLoggedInUserResponse = (response) => {
        handleUserResponse(response);
        LocalStorage.setAuthenthicationToken(response.data.access_token);
        LocalStorage.setRefreshToken(response.data.refresh_token);
        // console.log("logged-user token", response.data.access_token);
        // console.log("logged-user refresh", response.data.refresh_token);
    };

    const handleUserResponse = (response) => {
        userRef.current = response.data.user;
        setUser(response.data.user)
        LocalStorage.setCurrentUser(response.data.user);
    };

    const handleLogoutUser = () => {
        userRef.current = null;
        setUser(null);
        LocalStorage.setAuthenthicationToken(null);
        LocalStorage.setRefreshToken(null);
    };


    const logout = () => {
        signOut(auth)
            .then(() => {
                handleLogoutUser();
            })
            .catch((error) => {
                console.log(error);
            });
    };


    const getUserSubscription = useCallback(
        async (onSuccess, onError) => {
            getSubscriptionAPICAll(
                function (response) {
                    onSuccess(response);
                },
                onError
            );
        }
    );

    const addUserSubscription = useCallback(
        async (product_type, onSuccess, onError) => {
            addSubscriptionAPICAll(
                product_type,
                function (response) {
                    onSuccess(response);
                },
                onError
            );
        }
    );

    const cancelUserSubscription = useCallback(
        async (onSuccess, onError) => {
            cancelSubscriptionAPICAll(
                function (response) {
                    onSuccess(response);
                },
                onError
            );
        }
    );


    useEffect(() => {
        authObserver();
    }, []);

    return (
        <UserContext.Provider
            value={{
                user,
                userRef,
                currentUser,
                handleSetUser,
                signInWithPhone,
                verifyOTP,
                authObserver,
                fetchCurrentUser,
                emailExistInFirebase,
                phoneNumberExistInFirebase,
                logout,
                createUser,
                handleUserResponse,
                deleteUser,
                updateUser,
                getUserSubscription,
                addUserSubscription,
                cancelUserSubscription

            }}
        >
            {children}
        </UserContext.Provider>
    );
};

export const useUserContext = () => useContext(UserContext);

export default UserContextProvider;
