import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const BlankHome = () => {

    const navigate = useNavigate();

    useEffect(() => {
        navigate("/dashboard")
    }, [])

    return (
        <div></div>
    )
}

export default BlankHome