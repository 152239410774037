import React, { useState } from 'react'
import styled from 'styled-components'
import { colors } from '../../Assets/colors/colors'

import CustomPhoneInput from '../UIGeneric/CustomPhoneInput'
import PrimaryButton from '../UIGeneric/PrimaryButton'
import { useNavigate, useNavigation } from 'react-router-dom'
const LoginWithPhone = ({ containerStyle, handleLoginBtn, phone, setPhone, defaultCountry }) => {

    const navigate = useNavigate()
    return (
        <Container style={containerStyle}>
            <TitleWrapper>
                <h1>Welcome Back</h1>
                <p>
                    Please log in to access your account.
                </p>
            </TitleWrapper>
            <PhoneWrapper style={{ marginTop: "10px" }}>
                <h1>What’s your phone number?</h1>
                <CustomPhoneInput
                    value={phone}
                    setValue={setPhone}
                    defaultCountry={defaultCountry}
                />
            </PhoneWrapper>
            <ButtonWrapper >
                <PrimaryButton
                    onClick={handleLoginBtn}
                    disabled={phone?.length < 7}
                    text={"Log in"}
                />
                <p>Don’t have an account yet? <span onClick={() => navigate("/register")}>Sign up here</span>
                </p>
            </ButtonWrapper>

        </Container>
    )
}

export default LoginWithPhone
const Container = styled.div`
display:flex;
flex-direction:column;
gap:40px;

`
const TitleWrapper = styled.div`
display:flex;
flex-direction:column;
h1{
    font-size:24px;
    color:${colors.darkColor};
    font-family:"Sailec-Medium";
    line-height:32px;
}
p{
    font-size:14px;
    color:${colors.darkColor};
    font-family:"Sailec-Regular";
    line-height:20px;
}
`
const PhoneWrapper = styled.div`
display:flex;
flex-direction:column;
gap:10px;
h1{
    font-size:16px;
    color:${colors.darkColor};
    font-family:"Sailec-Medium";
    line-height:24px;
}
`
const ButtonWrapper = styled.div`
display:flex;
flex-direction:column;
gap:20px;
p{
    font-size:14px;
    color:${colors.darkColor};
    font-family:"Sailec-Regular";
}
span{
    cursor:pointer;
    font-weight:600;
    font-size:14px;
    color:${colors.darkColor};
    font-family:"Sailec-Medium";
}
`