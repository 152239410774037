import React, { useState } from 'react'
import styled from 'styled-components'
import { colors } from '../../Assets/colors/colors'

import PrimaryButton from '../UIGeneric/PrimaryButton'
import OTPInput from 'react-otp-input'

const ChangePhoneNumberEnterCode = ({ containerStyle, title, subtitle, subtitleSpan, otp, setOtp, wrongCode, setWrongCode, buttonAction, handleResendOTP }) => {


    return (
        <Container style={containerStyle}>
            {title &&
                <TitleWrapper>
                    <h1>{title}</h1>
                </TitleWrapper>
            }

            {subtitle &&
                <TitleWrapper>
                    <p>{subtitle} <br /><span>{subtitleSpan}</span></p>
                </TitleWrapper>
            }

            <PhoneWrapper style={{ marginTop: "10px" }}>
                <h1>Now enter the code we sent you</h1>
                <OtpInputWrapper>
                    <OTPInput
                        containerStyle={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                            gap: "3px"
                        }}
                        shouldAutoFocus
                        inputStyle={wrongCode ? { borderColor: "red" } : {}}
                        value={otp}
                        onChange={setOtp}
                        numInputs={6}
                        renderSeparator={<span> </span>}
                        renderInput={(props) => <input id="opt-input" {...props} />}
                    />
                </OtpInputWrapper>
            </PhoneWrapper>
            <ButtonWrapper >
                <PrimaryButton
                    disabled={otp.length < 6}
                    text={"Verify Code"}
                    onClick={buttonAction}
                />
                <p>Didn’t receive any code? <span onClick={() => handleResendOTP()}>Resend code</span>
                </p>
            </ButtonWrapper>

        </Container>
    )
}

export default ChangePhoneNumberEnterCode
const Container = styled.div`
display:flex;
flex-direction:column;
gap:40px;

`
const TitleWrapper = styled.div`
display:flex;
flex-direction:column;
h1{
    font-size:24px;
    color:${colors.darkColor};
    font-family:"Sailec-Medium";
    line-height:32px;
}
p{
    font-size:14px;
    color:${colors.darkColor};
    font-family:"Sailec-Regular";
    line-height:20px;
    span{
        font-weight:600;
    }
}
`
const PhoneWrapper = styled.div`
display:flex;
flex-direction:column;
gap:20px;
h1{
    font-size:16px;
    color:${colors.darkColor};
    font-family:"Sailec-Medium";
    line-height:24px;
}
`
const ButtonWrapper = styled.div`
display:flex;
flex-direction:column;
gap:20px;
p{
    font-size:14px;
    color:${colors.darkColor};
    font-family:"Sailec-Medium";
}
span{
    cursor:pointer;
    font-size:14px;
    color:${colors.darkColor};
    font-weight:600;
    font-family:"Sailec-Medium";
}
`

const OtpInputWrapper = styled.div`
  display: flex;
  width: 100%;
  input {
    padding: 14px 16px;
    min-width: 50px;
    border-radius: 10px;
    border: 1px solid #306d6d4d;
    color: ${colors.darkColor};
    background: ${colors.newChatModalInputBg};
    width: 100%;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    &:focus {
        outline: none;
      }
  }
  
`;