import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'


const DeleteConfirmation = ({ handleRemove, open, handleClose }) => {


    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" >{"Warning"}</DialogTitle>
                <DialogContent >
                    <DialogContentText id="alert-dialog-description" >
                        Are you sure you want to delete your account? This will also cancel your subscription.
                    </DialogContentText>
                </DialogContent>
                <DialogActions  >
                    <Button onClick={handleClose}>  Cancel</Button>
                    <Button style={{ color: "red" }} onClick={handleRemove} autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog >
        </>
    )
}

export default DeleteConfirmation