import React, { useState } from 'react'
import styled from 'styled-components'
import { colors } from '../../Assets/colors/colors'

import CustomPhoneInput from '../UIGeneric/CustomPhoneInput'
import PrimaryButton from '../UIGeneric/PrimaryButton'


const ChangePhoneNumberEnterNumber = ({ containerStyle, handleContinue, phone, setPhone }) => {
    return (
        <Container style={containerStyle}>

            <PhoneWrapper style={{ marginTop: "10px" }}>
                <h1>What’s your phone number?</h1>
                <CustomPhoneInput
                    value={phone}
                    setValue={setPhone}
                />
            </PhoneWrapper>
            <ButtonWrapper >
                <PrimaryButton
                    onClick={handleContinue}
                    disabled={phone?.length < 7}
                    text={"Continue"}
                />

            </ButtonWrapper>

        </Container>
    )
}

export default ChangePhoneNumberEnterNumber
const Container = styled.div`
display:flex;
flex-direction:column;
gap:40px;

`
const PhoneWrapper = styled.div`
display:flex;
flex-direction:column;
gap:10px;
h1{
    font-size:16px;
    color:${colors.darkColor};
    font-family:"Sailec-Medium";
    line-height:24px;
}
`
const ButtonWrapper = styled.div`
display:flex;
flex-direction:column;
gap:20px;
p{
    font-size:14px;
    color:${colors.darkColor};
    font-family:"Sailec-Regular";
}
span{
    cursor:pointer;
    font-weight:600;
    font-size:14px;
    color:${colors.darkColor};
    font-family:"Sailec-Medium";
}
`