import React from 'react'
import styled from 'styled-components'
import { colors } from '../../Assets/colors/colors'
import OfferItem from './OfferItem'
import PriceItem from './PriceItem'
import { ReactComponent as CheckCircleGold } from "../../Assets/icons/CheckCircleGold.svg"
import PrimaryButton from '../UIGeneric/PrimaryButton'

const PlanCard = ({ pro, title, checkList, insideCard, offers, mainPrice, secondaryPrice, selected, onClick, hadTrial, ...props }) => {

    return (

        <Card {...props} pro={pro}>
            <Line>
                <Line style={{ flexWrap: "wrap" }}>
                    {offers?.map((item, index) => (
                        <OfferItem gold={item.gold} text={item.text} key={index} />
                    ))}
                </Line>

                <Line style={{ marginLeft: "auto" }}>
                    <PriceItem gold price={mainPrice} week={secondaryPrice} style={{ marginLeft: "auto" }} />
                </Line>

            </Line>
            <Line>
                <Title>
                    {title}
                </Title>
            </Line>
            <InfoList>
                {checkList.map((item, index) => (
                    <ListItem key={index} goldCheck={item.goldCheck} goldText={item.goldText}>
                        <CheckCircleGold /> <p> {item.text}</p>
                    </ListItem>
                ))}

            </InfoList>
            <SeparatorLine />
            <PrimaryButton
                onClick={onClick}
                text={hadTrial ? "Select Plan" : "Start Free Trial"}
                icon={selected ? <CheckCircleGold /> : <></>}
                style={{ background: colors.darkColor, color: colors.white }}
            />
        </Card>

    )
}

export default PlanCard

const Card = styled.div`
display:flex;
flex-direction:column;
gap:25px;
padding:30px 34px;
border-radius:16px;
width:100%;
max-width:630px;
background:${colors.headerBorderColor};
overflow:hidden;
border:${props => props.pro ? `2px solid ${colors.cardGoldColor}` : "inherit"};
`

const Line = styled.div`
display:flex;
align-items:start;
gap:20px;
`
const Title = styled.h1`
font-size:30px;
font-family:"Sailec-Medium";
font-weight:500;
line-height:42px;
`
const InfoList = styled.ul`
display:flex;
flex-wrap:wrap;
row-gap:25px;
column-gap:80px;
`
const ListItem = styled.li`
display:flex;
align-items:center;
gap:15px;
min-width:225px;
p{
    font-size:16px;
    font-family:"Sailec-Regular";
    line-height:20px;
    color:${props => props.goldText ? colors.cardGoldColor : colors.darkColor};
}
svg{
    color:${props => props.goldCheck ? colors.cardGoldColor : colors.darkColor};
}
`

const SeparatorLine = styled.div`
height:4px;
background:${colors.headerBorderColor};
`