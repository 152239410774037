// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDQiv0SgkPRSptJHb6nvbN5xnyHiEjCzbo",
    authDomain: "beurzbyte-12437.firebaseapp.com",
    projectId: "beurzbyte-12437",
    storageBucket: "beurzbyte-12437.appspot.com",
    messagingSenderId: "690320325026",
    appId: "1:690320325026:web:45b80d2f7d4968a17e1afb",
    measurementId: "G-6XWELQGPN7"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const auth = getAuth(app)
