import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { colors } from '../../Assets/colors/colors'
import { ReactComponent as Xicon } from "../../Assets/icons/xIco.svg"
import { ReactComponent as QrCode } from "../../Assets/icons/qr-code-4.svg"
const QrCodeModal = ({ setIsModalOpen }) => {
    const ref = useRef();
    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            if (setIsModalOpen && ref.current && !ref.current.contains(e.target)) {
                setIsModalOpen(false);
            }
        };
        document.addEventListener("mousedown", checkIfClickedOutside);
        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    }, [setIsModalOpen]);

    useEffect(() => {
        const close = (e) => {
            if (e.keyCode === 27) {
                setIsModalOpen(false)
            }
        }
        window.addEventListener('keydown', close)
        return () => window.removeEventListener('keydown', close)
    }, [])
    return (
        <FullScreenContainer>
            <Card ref={ref}>
                <Line style={{ marginLeft: "auto" }}>
                    <CloseBtn onClick={() => setIsModalOpen(false)}>
                        <Xicon />
                    </CloseBtn>

                </Line>
                <CodeWrapper>
                    <QrCode />
                </CodeWrapper>

            </Card>
        </FullScreenContainer>
    )
}

export default QrCodeModal
const FullScreenContainer = styled.div`
position:fixed;
z-index:5;
top:0;
width:100%;
height:100%;
display:flex;
align-items:center;
justify-content:center;
background:${colors.cancelModalFullBg};
padding:20px;
`

const Card = styled.div`
display:flex;
flex-direction:column;
gap:10px;
border-radius:20px;
background:${colors.white};
padding:21px;

`

const Line = styled.div`
display:flex;
gap:20px;
align-items:center;
@media(max-width:510px){
  flex-direction:column-reverse;
}
`

const CloseBtn = styled.button`
cursor:pointer;
border:none;
display:flex;
background:none;
svg{
    height:12px;
}
`
const CodeWrapper = styled.div`
display:flex;
height:100%;
width:100%;
svg{
    height:100%;
width:100%;
}
`