import React from 'react'
import styled from 'styled-components'
import { colors } from '../../Assets/colors/colors'

const OfferItem = ({ gold, text }) => {
    return (
        <OfferItemWrapper gold={gold}>
            <p>{text}</p>
        </OfferItemWrapper>
    )
}

export default OfferItem


const OfferItemWrapper = styled.div`
display:flex;
align-items:center;
jutify-content:center;
padding:7px 20px;
background:${props => props.gold ? colors.cardGoldBg : colors.headerBorderColor};
border-radius:20px;
color:${props => props.gold ? colors.cardGoldColor : colors.darkColor};
min-width:fit-content;
p{
    font-family:"Sailec-Regular";
    font-size:15px;
    line-height:20px;

}

`