import { DELETE, GET, POST } from "../API";

export const currentUserAPICall = (onSuccess, onError) => {
    GET(
        process.env.REACT_APP_API_PATH + "/users/current",
        onSuccess,
        onError
    );
};

export const emailExistAPICall = (email, onSuccess, onError) => {
    const data = {
        email: email
    }

    POST(
        process.env.REACT_APP_API_PATH + "/users/email_exists",
        data,
        onSuccess,
        onError
    );
};

export const phoneNumberExistAPICall = (phone_number, onSuccess, onError) => {
    const data = {
        phone_number: phone_number
    }

    POST(
        process.env.REACT_APP_API_PATH + "/users/phone_number_exists",
        data,
        onSuccess,
        onError
    );
};

export const createUserAPICall = (name, email, fromWeb, onSuccess, onError) => {
    const data = {
        name: name,
        email: email,
        from_web: fromWeb
    }

    POST(
        process.env.REACT_APP_API_PATH + "/users/create",
        data,
        onSuccess,
        onError
    );
};

export const updateUserAPICall = (name, email, onSuccess, onError) => {
    const data = {
        name: name,
        email: email,

    }

    POST(
        process.env.REACT_APP_API_PATH + "/users/update",
        data,
        onSuccess,
        onError
    );
};

export const deleteUserAPICall = (onSuccess, onError) => {

    DELETE(
        process.env.REACT_APP_API_PATH + "/users/delete",
        onSuccess,
        onError
    );
};

