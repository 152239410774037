import React from "react";
import styled from "styled-components";
import { colors } from "../../Assets/colors/colors";
const InputWithTitleAndSubtitle = ({
  title,
  subtitle,
  style,
  ...inputProps
}) => {
  return (
    <Container style={style} >
      {title && <h1>{title}</h1>}
      {subtitle && <h2>{subtitle}</h2>}
      <InputContainer>
        <input
          {...inputProps}
        />
      </InputContainer>
    </Container>
  );
};

export default InputWithTitleAndSubtitle;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  h1 {
    color: ${colors.darkColor};
    font-size: 16px;
    line-height:23px;
    font-family:"Sailec-Medium";

  }
  h2 {
    color: ${colors.darkColor};
    font-size: 14px;
    line-height:19px;
    font-family:"Sailec-Regular";
    font-weight:500;
  }
`;

const InputContainer = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  padding: 7px 15px;
  border-radius: 10px;
  border: 2px solid ${colors.headerBorderColor};

  input {
    width: 100%;
    outline: none;
    border: none;
    color: ${colors.darkColor};
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;

  }

`;
