import React from 'react'
import styled from 'styled-components'
import BigStoreButton from '../UIGeneric/BigStoreButton'
import { ReactComponent as AppleIcon } from "../../Assets/icons/AppleIcon.svg"
import { ReactComponent as MagazinPlayIcon } from "../../Assets/icons/GooglePlayIcon.svg"
import { OUTLINED } from '../../Constants/constants'
import { colors } from '../../Assets/colors/colors'

const DownloadAppSection = ({ setShowQrCode }) => {

    const appleStoreClick = () => {
        window.open("https://apps.apple.com/us/app/beurzbyte-invest-smarter/id6443670820")
    }

    const androidStoreClick = () => {
        window.open("https://play.google.com/store/apps/details?id=com.beurzbyte.android&pli=1")
    }

    return (
        <Content>
            <Title>Download our App or scan QR</Title>
            <Subtitle>Please <span>download</span> our app from the App Store (iOS) or Google Play Store (Android) to access your subscription benefits on your mobile device.</Subtitle>
            <LineGroup>

                <BigStoreButton
                    icon={<AppleIcon />}
                    topText={"Download the"}
                    bottomText={"iOS App"}
                    onClick={() => appleStoreClick()}
                />
                <BigStoreButton
                    type={OUTLINED}
                    icon={<MagazinPlayIcon />}
                    color={colors.darkColor}
                    topText={"Download on"}
                    bottomText={"Android"}
                    onClick={() => androidStoreClick()}
                />
                <BigStoreButton
                    type={OUTLINED}
                    color={colors.darkColor}
                    bottomText={"See QR"}
                    onClick={() => setShowQrCode(true)}
                />
            </LineGroup>
        </Content>
    )
}

export default DownloadAppSection
const Content = styled.div`
padding:30px 90px;
display:flex;
flex-direction:column;
gap:40px;
width:100%;
padding-bottom:100px;
@media(max-width:990px){
    padding:30px 20px;
    }
`
const Title = styled.h1`
font-size:26px;
font-family:"Sailec-Medium";
font-weight:600;
line-height:38px;

`
const Subtitle = styled.h2`
margin-top:-20px;
font-size:16px;
font-family:"Sailec-Regular";
font-weight:500;
line-height:24px;
span{
    color:${colors.primaryPurple};
}
`
const LineGroup = styled.div`
display:flex;
gap:20px;
width:100%;
align-items:center;
@media(max-width:990px){
    flex-direction:column;
    }
`