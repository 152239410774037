import { GET, POST } from "../API";

export const getSubscriptionAPICAll = (onSuccess, onError) => {

    GET(
        process.env.REACT_APP_API_PATH + "/payments/stripe_subscription_details",
        onSuccess,
        onError
    );
};

export const addSubscriptionAPICAll = (product_type, onSuccess, onError) => {
    const data = {
        product_type: product_type
    }

    POST(
        process.env.REACT_APP_API_PATH + "/payments/create_subscription",
        data,
        onSuccess,
        onError
    );
};
export const cancelSubscriptionAPICAll = (onSuccess, onError) => {
    const data = {}

    POST(
        process.env.REACT_APP_API_PATH + "/payments/cancel_subscription",
        data,
        onSuccess,
        onError
    );
};