import React, { useEffect, useRef } from 'react'
import styled, { keyframes } from 'styled-components'
import { colors } from '../../Assets/colors/colors'
import { ReactComponent as Xicon } from "../../Assets/icons/xIco.svg"
import PrimaryButton from '../UIGeneric/PrimaryButton'
import moment from 'moment'
const CancelSubscriptionModal = ({ handleCancelSubscriptionBtn, setIsModalOpen, expireAt }) => {
    const ref = useRef();
    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            if (setIsModalOpen && ref.current && !ref.current.contains(e.target)) {
                setIsModalOpen(false);
            }
        };
        document.addEventListener("mousedown", checkIfClickedOutside);
        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    }, [setIsModalOpen]);

    useEffect(() => {
        const close = (e) => {
            if (e.keyCode === 27) {
                setIsModalOpen(false)
            }
        }
        window.addEventListener('keydown', close)
        return () => window.removeEventListener('keydown', close)
    }, [])
    return (
        <FullScreenContainer>
            <Card ref={ref}>
                <Line style={{ marginLeft: "auto" }}>
                    <CloseBtn onClick={() => setIsModalOpen(false)}>
                        <Xicon />
                    </CloseBtn>
                </Line>
                <Line>
                    <Title>
                        We’d hate to see you go!
                    </Title>
                </Line>
                <Line>
                    <Info>
                        Are you sure you want to cancel your subscription?
                    </Info>
                </Line>
                <Line>
                    <Info>
                        Your acces to all premium features of Beurzbyte would end on {moment(expireAt * 1000).format("DD MMM, YYYY")}
                    </Info>
                </Line>
                <Line style={{ marginLeft: "auto" }}>

                    <PrimaryButton
                        style={{ background: colors.darkColor, color: colors.white }}
                        onClick={handleCancelSubscriptionBtn}
                        text={"Cancel Plan"}
                    />

                </Line>


            </Card>
        </FullScreenContainer>
    )
}

export default CancelSubscriptionModal

const FullScreenContainer = styled.div`
position:fixed;
z-index:5;
top:0;
width:100%;
height:100%;
display:flex;
align-items:center;
justify-content:center;
background:${colors.cancelModalFullBg};
padding:20px;
`
const Card = styled.div`
display:flex;
flex-direction:column;
gap:10px;
border-radius:20px;
background:${colors.white};
padding:21px;
min-width:550px;
max-width:570px;
@media(max-width:990px){
    min-width:320px;
}
`
const Line = styled.div`
display:flex;
gap:20px;
align-items:center;
@media(max-width:510px){
  flex-direction:column-reverse;
}
`
const CloseBtn = styled.button`
cursor:pointer;
border:none;
display:flex;
background:none;
svg{
    height:12px;
}
`
const Title = styled.h1
    `
    color:${colors.darkColor};
font-size:20px;
font-family:"Sailec-Medium";
line-height:30px;
`
const Info = styled.p`
 color:${colors.darkColor};
font-size:13px;
font-family:"Sailec-Regular";
line-height:20px;

`
