import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import PlanCard from './PlanCard'
import { colors } from '../../Assets/colors/colors'
import OutlineButton from '../UIGeneric/OutlineButton'
import { OUTLINE_NORMAL } from '../../Constants/constants'
import PlanCardSubscribed from './PlanCardSubscribed'
import moment from 'moment'
import { useUserContext } from '../../Context/useUserContext'

const SubscribedPlan = ({ handleCancelSubscriptionButton, subscription, }) => {

    const { user } = useUserContext();
    const [nextPayment, setNextPayment] = useState("");

    const [currency, setCurrency] = useState("");
    const [activeSince, setActiveSince] = useState("");
    const [cancelsAt, setCancelsAt] = useState("");
    const [prices, setPrices] = useState("");


    const mapCurrency = (currency) => {
        if (currency === "usd") {
            return "$"
        } else {
            return currency
        }

    }

    var checkListMonthly = [
        {
            text: " Full access to all content",
            goldCheck: false,
            goldText: false
        },
        {
            text: "Cancel anytime",
            goldCheck: false,
            goldText: false
        },
        {
            text: "Billed yearly",
            goldCheck: false,
            goldText: false
        },
        {
            text: "7 days free trial",
            goldCheck: false,
            goldText: false
        },


    ]

    var checkListYearly = [
        {
            text: " Full access to all content",
            goldCheck: true,
            goldText: false
        },
        {
            text: "Cancel anytime",
            goldCheck: true,
            goldText: false
        },
        {
            text: "Billed yearly",
            goldCheck: true,
            goldText: false
        },

        {
            text: "7 days free trial",
            goldCheck: true,
            goldText: true
        },


    ]

    if (user.had_free_trial) {
        checkListMonthly.pop()
        checkListYearly.pop()
    }

    const monthlyCard = {
        title: "Beurzbyte Pro Monthly",
        proCard: false,
        checkList: checkListMonthly,
        mainPrice: (prices / 100).toFixed(2),
        secondaryPrice: ((prices / 100) / 4).toFixed(2),
    }

    const proYearly = {
        title: "Beurzbyte Pro Yearly",
        checkList: checkListYearly,
        proCard: true,
        mainPrice: (prices / 100).toFixed(2),
        secondaryPrice: ((prices / 100) / 52).toFixed(2),
    }

    const YearlySubscription = <PlanCardSubscribed
        pro={proYearly.proCard}
        title={proYearly.title}
        checkList={proYearly.checkList}
        insideCard={proYearly.insideCard}
        offers={proYearly.offers}
        mainPrice={proYearly.mainPrice}
        secondaryPrice={proYearly.secondaryPrice}
    />

    const MonthlySubscription = <PlanCardSubscribed
        pro={monthlyCard.proCard}
        title={monthlyCard.title}
        checkList={monthlyCard.checkList}
        insideCard={monthlyCard.insideCard}
        offers={monthlyCard.offers}
        mainPrice={monthlyCard.mainPrice}
        secondaryPrice={monthlyCard.secondaryPrice}
    />



    const showSubscription = () => {
        if (!subscription) {
            return
        }
        let display = <></>
        switch (subscription?.stripe_subscription?.plan?.interval) {
            case "year":
                display = YearlySubscription

                break;
            case "month":
                display = MonthlySubscription

                break;

            default:
                break;
        }
        return display

    }

    const mapPlan = () => {
        if (!subscription) {
            return
        }
        let plan = <></>
        switch (subscription?.stripe_subscription?.plan?.interval) {
            case "year":

                plan = `Yearly`
                break;
            case "month":

                plan = `Monthly`
                break;

            default:
                break;
        }
        return plan
    }

    const mapPrice = () => {
        if (!subscription) {
            return
        }
        let price = 0;
        switch (subscription?.stripe_subscription?.plan?.interval) {
            case "year":

                price = (prices / 100).toFixed(2)
                break;
            case "month":
                price = (prices / 100).toFixed(2)
                break;

            default:
                break;
        }
        return price
    }




    useEffect(() => {
        setPrices(subscription?.stripe_subscription.plan.amount)
        setNextPayment(subscription?.stripe_subscription.billing_cycle_anchor);
        setCurrency(subscription?.stripe_subscription.currency);
        setActiveSince(subscription?.stripe_subscription.created);
        setCancelsAt(subscription?.details.cancels_at);

    }, [subscription])


    return (
        <Content>
            <Title>
                Current plan
            </Title>
            {subscription?.details?.active &&
                <Line>
                    <Subtitle>
                        {mapPlan()} subscription plan active since: {moment(activeSince * 1000).format("MMM DD, YYYY")}.
                    </Subtitle>
                </Line>
            }
            {!subscription?.details?.active && <Line>
                <Subtitle >
                    You have a cancelled {mapPlan().toLowerCase()} subscription active until: {moment(cancelsAt).format("MMM DD, YYYY")}.
                </Subtitle>
            </Line>}
            <PlanDetails>

                {showSubscription()}
                {subscription?.details?.active &&
                    <DetailsWrapper>
                        <Line>
                            <Title>
                                Billing Information
                            </Title>
                        </Line>

                        <Line>
                            <GroupInfo>
                                <h2>Next Payment</h2>
                                {subscription?.details?.active &&
                                    <p>{moment(nextPayment * 1000).format("MMM DD, YYYY")}</p>}
                            </GroupInfo>
                            <GroupInfo>
                                <h2>Billed</h2>
                                {subscription?.details.active &&
                                    // <p> {moment(billed * 1000).format("MMM DD, YYYY")}</p>
                                    <p>{mapPlan()}</p>
                                }
                            </GroupInfo>
                            <GroupInfo>
                                <h2>Amount </h2>
                                {subscription?.details?.active &&
                                    <p>{mapCurrency(currency)} {mapPrice()}  </p>}
                            </GroupInfo>
                        </Line>

                        {subscription?.details?.active && <Line style={{ marginLeft: "auto" }}>
                            <OutlineButton
                                type={OUTLINE_NORMAL}
                                color={colors.errorRed}
                                text={"Cancel Plan"}
                                onClick={() => handleCancelSubscriptionButton()}
                            />
                        </Line>}

                    </DetailsWrapper>
                }
            </PlanDetails>
            <SeparatorLine />
        </Content>
    )
}

export default SubscribedPlan
const Content = styled.div`
padding:30px 70px;
display:flex;
flex-direction:column;
gap:40px;
width:100%;

@media(max-width:990px){
    padding:30px 20px;
    // align-items:center;
}
`
const Title = styled.h1`
font-size:26px;
font-family:"Sailec-Medium";
font-weight:500;
line-height:38px;
`
const Subtitle = styled.p`
font-size:16px;
font-family:"Sailec-Medium";
line-height:22px;
color:${colors.darkColor};
`

const PlanDetails = styled.div`
display:flex;
gap:100px;
@media(max-width:990px){
flex-direction:column;
 align-items:center;
}
`
const DetailsWrapper = styled.div`
display:flex;
flex-direction:column;
gap:20px;
width:100%;
@media(max-width:990px){
   align-items:center;
    }
`
const Line = styled.div`
display:flex;
gap:50px;
`
const GroupInfo = styled.div`
display:flex;
flex-direction:column;
gap:5px;
h2{
    color:${colors.darkColor};
    opacity:0.3;
    font-size:16px;
    font-family:"Sailec-Regular";
    font-weight:500;
    line-height:24px;
}
p{
    color:${colors.darkColor};
    font-size:16px;
    font-family:"Sailec-Medium";
    font-weight:500;
    line-height:24px;
}
`
const SeparatorLine = styled.div`
height:2px;
background:${colors.headerBorderColor};
`