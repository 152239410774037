import React, { useRef, useState, useEffect, } from 'react'
import styled from 'styled-components'
import Container1440 from '../Components/UIGeneric/Container1440'
import { colors } from '../Assets/colors/colors'
import { ReactComponent as LogoBig } from "../Assets/icons/beurzbyteLogoBig.svg"
import Ilustration3 from "../Assets/icons/ilustration4.webp"

import { auth } from '../firebase'


import { useUserContext } from '../Context/useUserContext'
import { PhoneAuthProvider, RecaptchaVerifier, updatePhoneNumber } from 'firebase/auth'
import { useLocation, useNavigate } from 'react-router-dom'
import { showErrorNotification, showGenericError, showSuccessNotification } from '../helper/Notifications'
import LoaderComponent from '../Components/UIGeneric/LoaderComponent'

import ChangePhoneNumberEnterCode from '../Components/ChangePhoneNumberComponents/ChangePhoneNumberEnterCode'
import ChangePhoneNumberEnterNumber from '../Components/ChangePhoneNumberComponents/ChangePhoneNumberEnterNumber'
import firebase from 'firebase/compat/app'
import LocalStorage from '../helper/LocalStorage'
import { isMobile } from 'react-device-detect'





const ChangePhoneNumberPage = () => {
    const location = useLocation();

    const { user } = useUserContext()

    const { signInWithPhone, verifyOTP, fetchCurrentUser, phoneNumberExistInFirebase, } = useUserContext();

    const navigate = useNavigate();

    const [phone, setPhone] = useState("");
    const [otp, setOtp] = useState("");
    const [wrongCode, setWrongCode] = useState();
    const [isLoading, setIsLoading] = useState(false)

    const [phase, setPhase] = useState(1);

    const confirmationRes = useRef();
    const verificationCode = useRef();
    const verificationId = useRef();
    const phoneCredential = useRef();
    const newTokenRef = useRef();
    const phoneAuthProvider = useRef(null);

    const initializeReCaptcha = () => {
        if (window.recaptchaVerifier == null) {
            window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
                'size': 'invisible',
                'callback': (response) => {
                    // reCAPTCHA solved, allow signInWithPhoneNumber.
                    //onSignInSubmit();
                },
            });
        }
    }

    const initializePhoneAuthProvider = () => {
        if (phoneAuthProvider.current == null) {
            phoneAuthProvider.current = new PhoneAuthProvider(auth)
        }
    }


    const resetRechaptcha = () => {
        window.recaptchaVerifier.recaptcha.reset();
    }

    const reinitializeRecaptchaContainer = () => {
        window.recaptchaVerifier = null;
        let element = document.getElementById("recaptcha-container");
        let parent = element.parentElement;
        parent.removeChild(element)
        let newElement = document.createElement("div")
        newElement.setAttribute("id", "recaptcha-container")
        parent.appendChild(newElement);
        window.PhoneProvide = null;


    }



    const updatePhone = async (newUser) => {
        const phoneCredential = PhoneAuthProvider.credential(verificationId.current, verificationCode.current);
        const resut = await updatePhoneNumber(newUser, phoneCredential);
        auth.currentUser.getIdToken(true).then((result) => {
            LocalStorage.setAuthenthicationToken(result);
            fetchCurrentUser(
                () => {
                    navigate("/dashboard")
                }
            );
        })

    }


    const handlePhoneNumberExist = () => {
        setIsLoading(true)
        phoneNumberExistInFirebase(
            phone,
            (response) => {
                if (response.data.exists === true) {
                    showErrorNotification("This number is already in use");
                    setIsLoading(false)
                } else {
                    handleVerifyNewPhoneNumberFirebase(phone)
                }
            },
            (error) => {
                console.log(error);
                showGenericError();
                setIsLoading(false)
            }
        )

    }

    const verifyPhone = async (phone) => {
        const provider = phoneAuthProvider.current
        verificationId.current = await provider.verifyPhoneNumber(phone, window.recaptchaVerifier)
        setPhase(3);
        setIsLoading(false)
    }

    const handleVerifyNewPhoneNumberFirebase = (phone) => {

        initializePhoneAuthProvider();

        if (window.recaptchaVerifier == null) {
            initializeReCaptcha();
        } else {
            resetRechaptcha();
        }

        verifyPhone(phone);

    }

    const initialSignIn = (phone) => {
        setIsLoading(true)
        if (window.recaptchaVerifier == null) {
            initializeReCaptcha();
        } else {
            resetRechaptcha();
        }

        signInWithPhone(
            phone,
            window.recaptchaVerifier,
            function (confirmationResult) {
                setIsLoading(false);
                confirmationRes.current = confirmationResult;
                setPhase(1);
                showSuccessNotification("Code sent");

            },
            function (error) {
                console.log(error, "err")
                showGenericError();
                setIsLoading(false)

            }
        )
    }

    const verifyInitialOTP = (otp, callback) => {
        setIsLoading(true)
        verifyOTP(
            confirmationRes.current,
            otp,
            function (response) {
                reinitializeRecaptchaContainer();
                setWrongCode(false);
                fetchCurrentUser(
                    (response) => {
                        verificationCode.current = otp;
                        callback && callback()

                    },
                    (error) => {
                        if (error?.response?.status === 404) {
                            setIsLoading(false)
                            showGenericError();
                            // navigate("/register", { state: { phone: phone } })
                        }
                    }
                )

            },
            function (error) {
                setOtp("");
                setWrongCode(true);
                console.log(error);
                showGenericError();
                setIsLoading(false);

            }
        );
    };


    const handleVerifyCode = (otp, callback) => {
        setIsLoading(true);
        phoneCredential.current = PhoneAuthProvider.credential(verificationId.current, otp);
        verificationCode.current = otp;
        callback && callback()

    };

    const handleResendOTP = () => {
        initialSignIn()
    }

    const autoCheckOtp = () => {
        if (otp.length == 6 && phase == 1) {
            verifyInitialOTP(otp,
                () => {
                    setPhase(2);
                    setIsLoading(false)
                    setOtp("");
                }
            );
        }
    };

    useEffect(() => {
        initialSignIn(user.phone_number);
    }, [])

    useEffect(() => {
        autoCheckOtp();
    }, [otp]);

    const phase1 =
        <ChangePhoneNumberEnterCode
            title={"Verify your old number"}
            subtitle={`Please enter the 6-digit conde sent to you at `}
            subtitleSpan={user.phone_number}
            otp={otp}
            setOtp={setOtp}
            wrongCode={wrongCode}
            setWrongCode={setWrongCode}
            containerStyle={{ width: "100%" }}
            buttonAction={() => {
                verifyInitialOTP(otp,
                    () => {
                        setPhase(2);
                        setIsLoading(false)
                        setOtp("");
                    }
                )
            }}
            handleResendOTP={handleResendOTP}

        />

    const phase2 =
        <ChangePhoneNumberEnterNumber
            phone={phone}
            setPhone={setPhone}
            handleContinue={() => handlePhoneNumberExist()}
        />


    const phase3 =
        <ChangePhoneNumberEnterCode
            title={"Change phone number"}
            subtitle={`Please enter the code we sent you `}
            otp={otp}
            setOtp={setOtp}
            wrongCode={wrongCode}
            setWrongCode={setWrongCode}
            containerStyle={{ width: "100%" }}
            buttonAction={() => handleVerifyCode(otp, () => {
                const newUser = auth.currentUser
                updatePhone(newUser)
            })}


        />

    const displayPhase = () => {
        switch (phase) {
            case 1:
                return phase1

            case 2:
                return phase2
            case 3:
                return phase3

            default:
                break;
        }
    }

    return (
        <LoaderComponent loading={isLoading}>
            <OutterWrapper>

                <Container1440 style={{ height: '100%' }}>
                    <div id="recaptcha-container"></div>
                    <Container>
                        <LeftSide>
                            <Content>
                                <Logo>
                                    <LogoBig />
                                </Logo>
                                <ContentWrapper>
                                    {displayPhase()}
                                </ContentWrapper>

                            </Content>
                        </LeftSide>
                        <RightSide>

                        </RightSide>

                    </Container>
                </Container1440>
                {!isMobile && <IllustrationWrapper>
                    <img src={Ilustration3} alt='illustration' />
                </IllustrationWrapper>}

            </OutterWrapper>
        </LoaderComponent>
    )
}

export default ChangePhoneNumberPage

const OutterWrapper = styled.div`
height:100%;
position:relative;

`
const Container = styled.div`
display:flex;
height:100%;
width:100%;
`
const Content = styled.div`
padding:30px 90px;
display:flex;
flex-direction:column;
width:100%;
align-items:center;
height:100%;
gap:40px;
@media(max-width:990px){
    padding:30px 20px;
}
`
const LeftSide = styled.div`
display:flex;
flex-direction:column;
width:100%;
background:${colors.white};
justify-content:center;
align-items:center;
height:100%;

`
const RightSide = styled.div`
display:flex;
width:100%;
height:100%;
@media(max-width:990px){
    display:none;
  }
`
const Logo = styled.div`
display:flex;
width:100%;

`
const ContentWrapper = styled.div`
display:flex;
align-items:center;
height:100%;
width:100%;
`
const IllustrationWrapper = styled.div`
position:fixed;
height:100%;
top:0;
right:0;

img{
    display:flex;
    height:100%;
    position:relative;
max-width:50vw;

}
@media(max-width:990px){
    display:none;
  }
`