import toast from "react-hot-toast"
import styled from "styled-components"

export const showGenericError = () => {
    toast.error("Something went wrong, please try again")
}
export const showErrorNotification = (message) => {
    toast.error(message)
}

export const showSuccessNotification = (message) => {
    toast.success(message)
    // toast.custom(
    //     <CustomToast>
    //         <h1>ToastTitle</h1>
    //         <p>Toast message</p>
    //     </CustomToast>
    //     ,
    //     {
    //         duration: "500"
    //     }
    // )
}


const CustomToast = styled.div`
display:flex;
align-items:center;
flex-direction:column;
gap:10px;
background:white;
border-radius:10px;
border-left:3px solid green;
`