import { useEffect, useState } from "react";
import LoaderComponent from "./Components/UIGeneric/LoaderComponent";
import { Outlet, useNavigate } from "react-router-dom";
import styled from "styled-components";
import Header from "./Components/Header/Header";

function App() {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <>
      <LoaderComponent loading={isLoading}>
        <Container>
          <Header />
          <Outlet
            context={{
              isLoading,
              setIsLoading
            }} />
        </Container>
      </LoaderComponent>
    </>
  );
}

export default App;

const Container = styled.div`
display:flex;
flex-direction:column;
height:100%;
overflow:auto;
`