import React from 'react'
import styled from 'styled-components'
import PlanCard from './PlanCard'
import { colors } from '../../Assets/colors/colors'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick'
import { useUserContext } from '../../Context/useUserContext';
import { showGenericError } from '../../helper/Notifications';
import { MONTHLY, YEARLY } from '../../Constants/constants';
import { useOutletContext } from 'react-router-dom';

const NoPlanPage = ({ prices }) => {
    const { addUserSubscription } = useUserContext();
    const { user } = useUserContext();
    const { setIsLoading } = useOutletContext()

    const handleAddSubscription = (type) => {
        setIsLoading(true)
        addUserSubscription(
            type,
            (response) => {
                setIsLoading(false);
                const link = response.data.url;
                window.location = link;

            },
            () => {
                setIsLoading(false);
                showGenericError()
            }
        )
    }

    var checkListMonthly = [
        {
            text: " Full access to all content",
            goldCheck: false,
            goldText: false
        },
        {
            text: "Cancel anytime",
            goldCheck: false,
            goldText: false
        },
        {
            text: "Billed yearly",
            goldCheck: false,
            goldText: false
        },
        {
            text: "7 days free trial",
            goldCheck: false,
            goldText: false
        },


    ]

    var checkListYearly = [
        {
            text: " Full access to all content",
            goldCheck: true,
            goldText: false
        },
        {
            text: "Cancel anytime",
            goldCheck: true,
            goldText: false
        },
        {
            text: "Billed yearly",
            goldCheck: true,
            goldText: false
        },

        {
            text: "7 days free trial",
            goldCheck: true,
            goldText: true
        },
    ]

    var offersYearly = [

        {
            text: "$0.00 TODAY",
            gold: false
        },
    ]

    var offersMonthly = [

        {
            text: "$0.00 TODAY",
            gold: false
        },
    ]

    if (user.had_free_trial) {
        checkListMonthly.pop()
        checkListYearly.pop()
        offersYearly.pop()
        offersMonthly.pop()
    }

    const proMonthly = {
        title: "Beurzbyte Pro Monthly",
        proCard: false,
        offers: offersMonthly,
        checkList: checkListMonthly,
        mainPrice: (prices?.monthly.price / 100).toFixed(2),
        secondaryPrice: ((prices?.monthly.price / 100) / 4).toFixed(2),
        onClick: () => handleAddSubscription(MONTHLY)

    }

    const proYearly = {
        title: "Beurzbyte Pro Yearly",
        checkList: checkListYearly,
        proCard: true,
        offers: offersYearly,
        mainPrice: (prices?.yearly.price / 100).toFixed(2),
        secondaryPrice: ((prices?.yearly.price / 100) / 52).toFixed(2),
        onClick: () => handleAddSubscription(YEARLY)

    }

    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,

    };

    function CustomSlide(props) {
        const { index, children, ...otherProps } = props;
        return (
            <CustomSlieWrapper {...otherProps}>
                {children}
            </CustomSlieWrapper>
        );
    }

    return (

        <Content>
            <Title>
                Manage your plan
            </Title>
            <CardList>
                <PlanCard
                    pro={proYearly.proCard}
                    style={{ minWidth: "375px" }}
                    title={proYearly.title}
                    checkList={proYearly.checkList}
                    insideCard={proYearly.insideCard}
                    mainPrice={proYearly.mainPrice}
                    secondaryPrice={proYearly.secondaryPrice}
                    offers={proYearly.offers}
                    onClick={proYearly.onClick}
                    hadTrial={user.had_free_trial}
                />

                <PlanCard
                    pro={proMonthly.proCard}
                    style={{ minWidth: "375px" }}
                    title={proMonthly.title}
                    checkList={proMonthly.checkList}
                    insideCard={proMonthly.insideCard}
                    mainPrice={proMonthly.mainPrice}
                    secondaryPrice={proMonthly.secondaryPrice}
                    offers={proMonthly.offers}
                    onClick={proMonthly.onClick}
                    hadTrial={user.had_free_trial}
                />

            </CardList>
            <CarouselWrapper>
                <Slider {...settings}>
                    <CustomSlide  >
                        <PlanCard
                            pro={proYearly.proCard}
                            title={proYearly.title}
                            checkList={proYearly.checkList}
                            insideCard={proYearly.insideCard}
                            mainPrice={proYearly.mainPrice}
                            secondaryPrice={proYearly.secondaryPrice}
                            offers={proYearly.offers}
                            onClick={proYearly.onClick}
                        />
                    </CustomSlide>
                    <CustomSlide >
                        <PlanCard
                            pro={proMonthly.proCard}
                            title={proMonthly.title}
                            checkList={proMonthly.checkList}
                            insideCard={proMonthly.insideCard}
                            mainPrice={proMonthly.mainPrice}
                            secondaryPrice={proMonthly.secondaryPrice}
                            offers={proMonthly.offers}
                            onClick={proMonthly.onClick}
                        />
                    </CustomSlide>
                </Slider>
            </CarouselWrapper>
            <SeparatorLine />
        </Content >
    )
}

export default NoPlanPage

const Content = styled.div`
padding:30px 90px;
display:flex;
flex-direction:column;
gap:40px;
width:100%;
overflow:hidden;
@media(max-width:990px){
    padding:30px 20px;
}
`
const Title = styled.h1`
font-size:26px;
font-family:"Sailec-Regular";
font-weight:600;
line-height:38px;
`

const SeparatorLine = styled.div`
height:2px;
background:${colors.headerBorderColor};
`
const CardList = styled.div`
display:flex;
gap:25px;

@media(max-width:990px){
justify-content:center;
display:none;
}
`

const CarouselWrapper = styled.div`
display:none;
flex-direction:column;
@media(max-width:990px){
    display:block;
}
`
const CustomSlieWrapper = styled.div`
display:flex !important;
justify-content:center;
`