export const colors = {
    primaryPurple: "#9040F7",
    darkColor: "rgba(40, 44, 50, 1)",
    white: "#FFFFFF",
    lightPurple: "#7A73FF",
    primaryGradient: "linear-gradient(90deg, rgba(82,73,245,1) 0%, rgba(221,50,247,1) 100%)",
    secondaryGradient: "linear-gradient(90deg, rgba(144,138,255,1) 0%, rgba(233,89,255,1) 100%)",
    cardGoldColor: "rgba(181, 155, 68, 1)",
    cardGoldBg: "rgba(181, 155, 68, 0.16)",
    errorRed: "#FF607C",
    inputBorder: "#EEEDF1",
    fullScreenModalBg: "rgba(37, 39, 79,0.8)",
    validGreen: "#7FC998",
    headerBorderColor: "rgba(40, 44, 50, 0.1)",
    inputWithActionBg: "rgba(239, 239, 239, 1)",
    cancelModalFullBg: "rgba(40, 44, 50, 0.45)"

}