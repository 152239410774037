import React from 'react'
import styled from 'styled-components'
import { colors } from '../../Assets/colors/colors'
import { ReactComponent as Logo } from "../../Assets/icons/Logo-1.svg"
import UserButton from '../GenericComponents/UserButton'
import Container1440 from '../UIGeneric/Container1440'
import { useNavigate } from 'react-router-dom'
import PrimaryButton from '../UIGeneric/PrimaryButton'

const Header = () => {
    const navigate = useNavigate();
    const options = [
        {
            text: "Account",
            action: () => navigate("/account")
        },

    ]

    return (
        <Container>
            <Container1440>
                <CustomHeader>
                    <UserButton />
                    <RightSideOptions>
                        {options.map((option, index) => (
                            <HeaderOptionBtn key={index} onClick={option.action}>
                                {option.text}
                            </HeaderOptionBtn>
                        ))}
                        <PrimaryButton
                            onClick={(e) => { navigate("/dashboard") }}
                            text={"Manage Pro Plan"}
                        />
                    </RightSideOptions>

                </CustomHeader>
            </Container1440>
        </Container>
    )
}

export default Header
const Container = styled.div`
padding:0 70px;
@media(max-width:990px){
    padding:0;
}
`
const CustomHeader = styled.header`
display:flex;
align-items:center;
padding:17px;
min-height:80px;
background:${colors.white};
width:100%;
gap:25px;
border-bottom:4px solid ${colors.headerBorderColor};
`
const LogoWrapper = styled.div`
overflow:hidden;
display:flex;
svg{
    max-height:45px;
}
`
const HeaderOptionBtn = styled.button`
display:flex;
align-items:center;
background:none;
border:none;
cursor:pointer;
    color:${colors.darkColor};
    font-size:16px;
    line-height:20px;
    font-family:"Sailec-Medium";

`
const RightSideOptions = styled.div`
display:flex;
align-items:center;
gap:25px;
margin-left:auto;

`