import { createBrowserRouter } from "react-router-dom";
import App from "../App";
import AccountPage from "../Pages/AccountPage";
import PlansPage from "../Pages/PlansPage";
import LoginPage from "../Pages/LoginPage";
import RegisterPage from "../Pages/RegisterPage";
import ProtectedRoute from "./ProtectedRoute";
import UserContextProvider from "../Context/useUserContext";
import ChangePhoneNumberPage from "../Pages/ChangePhoneNumberPage";
import BlankHome from "../Pages/BlankHome";




export const router = createBrowserRouter([

    {
        index: "/",
        element: <BlankHome />,
        path: "/"
    },

    {
        index: "*",
        element:
            <ProtectedRoute>
                <App />
            </ProtectedRoute>

        ,
        children: [
            {
                path: "/dashboard",
                element: <PlansPage />
            },
            {
                path: "/account",
                element: <AccountPage />
            },



        ]
    },


    {
        element: <LoginPage />,
        path: "/login"
    },
    {
        element: <RegisterPage />,
        path: "/register"
    },
    {
        path: "/phoneNumber",
        element: <ProtectedRoute>
            <ChangePhoneNumberPage />
        </ProtectedRoute>
    },



])