import React from 'react'
import styled from 'styled-components'
import { colors } from '../../Assets/colors/colors'

const CustominputWithAction = ({ title, containerStyle, buttonText, buttonAction, inputRef, buttonIcon, ...inputProps }) => {
    return (
        <CustomInptWithActionContainer style={containerStyle}>
            <NameWrapper>
                <LeftBorder />
                <h1>{title}</h1>
            </NameWrapper>

            <InputContainer>
                <CustomInput
                    ref={inputRef}
                    {...inputProps}
                />
                <ActionButton
                    onClick={buttonAction}
                >
                    {buttonIcon}
                    {buttonText}
                </ActionButton>
            </InputContainer>

        </CustomInptWithActionContainer>
    )
}

export default CustominputWithAction

const CustomInptWithActionContainer = styled.div`
display:flex;
flex-direction:column;
gap:10px;
`
const InputContainer = styled.div`
display:flex;
align-items:center;
gap:10px;
background:${colors.inputWithActionBg};
border-radius:10px;
padding:16px 20px;
`
const CustomInput = styled.input`
font-size:16px;
font-family:"Sailec-Regular";
font-weight:400;
line-height:20px;
border:none;
outline:none;
width:100%;
background:none;
`
const ActionButton = styled.button`
font-size:14px;
font-family:"Sailec-Medium";
font-weight:400;
line-height:16px;
color:${colors.primaryPurple};
border:none;
background:none;
cursor:pointer;
gap:10px;
display:flex;
align-items:center;
`
const NameWrapper = styled.div`
h1{
        font-size:16px;
        font-family:"Sailec-Regular";
        line-height:20px;

    }

    display:flex;
    align-items:center;
    gap:5px;
`
const LeftBorder = styled.div`
display:flex;
min-width:2px;
background:${colors.primaryPurple};
height:100%;
max-height:12px;
`