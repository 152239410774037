import React from 'react'
import styled from 'styled-components'
import { colors } from '../../Assets/colors/colors'
import { OUTLINED } from '../../Constants/constants'
const BigStoreButton = ({ type, icon, topText, bottomText, background, color, onClick }) => {


    const regular = <Regular background={background} color={color} onClick={onClick}>
        {icon && <IconWrapper color={color}>
            {icon}
        </IconWrapper>}

        <TxtWrapper color={color}>
            <h1 >{topText}</h1>
            <h2 >{bottomText}</h2>
        </TxtWrapper>
    </Regular>

    const outlined = <Outlined background={background} color={color} onClick={onClick}>
        {icon && <IconWrapper color={color}>
            {icon}
        </IconWrapper>}

        <TxtWrapper color={color}>
            <h1 >{topText}</h1>
            <h2 >{bottomText}</h2>
        </TxtWrapper>
    </Outlined>

    return (
        <>
            {type === OUTLINED ? outlined : regular
            }
        </>


    )
}

export default BigStoreButton

const Regular = styled.button`
display:flex;
color:${colors.white};
border-radius:10px;
border:none;
cursor:pointer;
height:fit-content;
padding:11px 37px;
gap:20px;
align-items:center;
justify-content:center;
background:${props => props.background ? props.background : colors.darkColor};
min-height:60px;
max-height:60px;
min-width:190px;
`
const IconWrapper = styled.div`
align-items:center;
display:flex;
height:100%;
color:${props => props.color ? props.color : colors.white};
max-width:26px;
max-height:28px;

`

const TxtWrapper = styled.div`
display:flex;
flex-direction:column;
h1{
font-size:10px;
font-family:"Sailec-Regular";
line-height:14px;
color:${props => props.color ? props.color : colors.white};
}
h2{
    font-size:16px;
    font-family:"Sailec-Medium";
    line-height:24px;
    color:${props => props.color ? props.color : colors.white};
}
`

const Outlined = styled.div`
min-width:190px;
min-height:60px;
max-height:60px;
display:flex;
justify-content:center;
background:${colors.secondaryGradient};
color:${colors.white};
border-radius:10px;
border:none;
cursor:pointer;
height:fit-content;
padding:11px 37px;
gap:20px;
align-items:center;
border:2px solid ${props => props.color ? props.color : colors.darkColor};
background:${props => props.background ? props.background : colors.white};
`