import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { colors } from '../Assets/colors/colors'
import Container1440 from '../Components/UIGeneric/Container1440'
import { ReactComponent as LogoBig } from "../Assets/icons/beurzbyteLogoBig.svg"
import Ilustration12 from "../Assets/icons/loginIlustration_2x.webp"
import Ilustration3 from "../Assets/icons/ilustration4.webp"
import PhaseCounter from '../Components/LoginComponents/PhaseCounter'
import RegisterWithPhoneNumber from '../Components/LoginComponents/RegisterWithPhoneNumber'
import EnterCode from '../Components/LoginComponents/EnterCode'
import { useLocation, useNavigate } from 'react-router-dom'
import { isEmailValid, isNameValid } from '../helper/ValidatorsHelper'
import { useUserContext } from '../Context/useUserContext'
import { RecaptchaVerifier } from 'firebase/auth'
import { auth } from '../firebase'
import { showGenericError, showSuccessNotification } from '../helper/Notifications'
import LoaderComponent from '../Components/UIGeneric/LoaderComponent'
import { useDefaultCountry } from '../hooks/useGeolocationDb'
import { isMobile } from 'react-device-detect'

const RegisterPage = () => {

    const { phoneNumberExistInFirebase, emailExistInFirebase, createUser, signInWithPhone, verifyOTP, handleUserResponse } = useUserContext()
    const [phase, setPhase] = useState(1);
    const [phone, setPhone] = useState("");
    const [agreeTerms, setAgreeTerms] = useState(false);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [showMoreDetails, setShowMoreDetails] = useState(false);
    const [phoneNumberError, setPhoneNumberError] = useState(false);
    const [continueDisabled, setContinueDisabled] = useState(false);
    const [emailError, setEmailError] = useState(false)
    const [wrongCode, setWrongCode] = useState(false)
    const [otp, setOtp] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [defaultCountry] = useDefaultCountry()

    const navigate = useNavigate();
    const location = useLocation();
    const { state } = useLocation();
    const confirmationRes = useRef();

    const initializeReCaptcha = () => {
        if (window.recaptchaVerifier == null) {
            window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container-register', {
                'size': 'invisible',
                'callback': (response) => {
                    // reCAPTCHA solved, allow signInWithPhoneNumber.
                    //onSignInSubmit();
                },
            });
        }
    }

    const resetRechaptcha = () => {
        window.recaptchaVerifier.recaptcha.reset();
    }

    const reinitializeRecaptchaContainer = () => {
        window.recaptchaVerifier = null;
        let element = document.getElementById("recaptcha-container-register");
        let parent = element.parentElement;
        parent.removeChild(element)
        let newElement = document.createElement("div")
        newElement.setAttribute("id", "recaptcha-container-register")
        parent.appendChild(newElement);

    }


    const handleContinueButton = () => {
        handleExistPhoneNumber(phone);
        if (showMoreDetails) {
            handleExistPhoneNumber(phone, () => {
                handleExistEmail(email)
            });

        }

    }

    const phase1 =
        <RegisterWithPhoneNumber
            phone={phone}
            setPhone={setPhone}
            agreeTerms={agreeTerms}
            setAgreeTerms={setAgreeTerms}
            name={name}
            setName={setName}
            email={email}
            setEmail={setEmail}
            showMoreDetails={showMoreDetails}
            phoneNumberError={phoneNumberError}
            continueDisabled={continueDisabled}
            handleContinueButton={handleContinueButton}
            emailError={emailError}
            defaultCountry={defaultCountry}
        />

    const phase2 =
        <EnterCode
            otp={otp}
            setOtp={setOtp}
            noTitle
            containerStyle={{ marginTop: "20px", width: "100%" }}
            wrongCode={wrongCode}
            buttonAction={() => handleVerifyCode(otp)}
        />

    const displayPhase = () => {
        switch (phase) {
            case 1:
                return phase1
            case 2:
                return phase2
            default:
                break;
        }

    }

    const handleRegisterUser = (name, email) => {
        createUser(
            name, email, true,
            (response) => {
                handleUserResponse(response)
                if (location.state?.backTo) {
                    setIsLoading(false)
                    navigate(`${location.state?.backTo}`)
                } else {
                    setIsLoading(false)
                    navigate(`/dashboard`)
                }

            },
            (error) => {
                setIsLoading(false)
                showGenericError();
            }

        )
    }

    const handleSignInFirebase = () => {
        setIsLoading(true)
        if (window.recaptchaVerifier == null) {
            initializeReCaptcha();
        } else {
            resetRechaptcha();
        }

        signInWithPhone(
            phone,
            window.recaptchaVerifier,
            function (confirmationResult) {
                confirmationRes.current = confirmationResult;
                setPhase(2);
                setIsLoading(false);
                showSuccessNotification("Code sent");
            },
            function (error) {
                showGenericError();
                setIsLoading(false)
            }
        )
    }

    const handleVerifyCode = (otp) => {
        setIsLoading(true)
        verifyOTP(
            confirmationRes.current,
            otp,
            function (response) {
                reinitializeRecaptchaContainer();
                setWrongCode(false);
                handleRegisterUser(name, email)
            },
            function (error) {
                setOtp("");
                setWrongCode(true);
                showGenericError();
                setIsLoading(false)

            }
        );
    };

    const getToken = () => {
        handleSignInFirebase();
    }

    const checkContinueBtnDisabled = () => {
        if (!showMoreDetails) {
            if (phone?.length < 7 || !agreeTerms) {
                setContinueDisabled(true)
            } else {
                setContinueDisabled(false)
            }
        } else
            if (
                phone?.length < 7 ||
                !agreeTerms ||
                !isNameValid(name) ||
                !isEmailValid(email)

            ) {
                setContinueDisabled(true)
            } else {
                setContinueDisabled(false)
            }
    }

    const handleExistPhoneNumber = (phone, callback) => {
        phoneNumberExistInFirebase(
            phone,
            (response) => {
                if (response.data) {
                    setPhoneNumberError(response.data.exists);
                    if (response.data.exists == false) {
                        setShowMoreDetails(true)
                        if (callback) {
                            callback()
                        }

                    }
                }
            },
            (error) => {
                showGenericError();
            }
        )
    }

    const handleExistEmail = (email,) => {
        emailExistInFirebase(
            email,
            (response) => {
                if (response.data) {
                    setEmailError(response.data.email_exists)
                    if (response.data.email_exists == false) {
                        getToken();
                    }
                }
            },
            (error) => {
                showGenericError();
            }
        )
    }

    const autoCheckOtp = () => {
        if (otp.length !== 6) {
        } else {
            handleVerifyCode(otp);
        }
    };


    useEffect(() => {
        if (state?.phone) {
            setPhone(state?.phone)
        }
    }, [])


    useEffect(() => {
        checkContinueBtnDisabled();
    }, [name, phone, email, agreeTerms, phoneNumberError, showMoreDetails])



    useEffect(() => {
        autoCheckOtp();
    }, [otp]);


    return (
        <LoaderComponent loading={isLoading}>
            <OutterWrapper>
                <Container1440 style={{ height: '100%', }}>
                    <div id="recaptcha-container-register"></div>
                    <Container>
                        <LeftSide>
                            <Content>
                                <Logo>
                                    <LogoBig />
                                </Logo>
                                <InsideWrapper>
                                    <PhaseCounter
                                        phase={phase}
                                    />
                                    {displayPhase()}
                                </InsideWrapper>

                            </Content>
                        </LeftSide>
                        <RightSide>

                        </RightSide>

                    </Container>
                </Container1440>

                {!isMobile && <Cover>
                    {phase === 1 && <img src={Ilustration12} alt='' />}
                    {phase === 2 && <img src={Ilustration3} alt='' />}

                </Cover>}

            </OutterWrapper>
        </LoaderComponent>
    )
}

export default RegisterPage


const OutterWrapper = styled.div`
height:100%;
position:relative;
width:100%;
`
const Container = styled.div`
display:flex;
height:100%;
width:100%;

`
const Content = styled.div`
padding:30px 90px;
display:flex;
flex-direction:column;
width:100%;
align-items:center;
height:100%;
gap:40px;
  scrollbar-width: none; 
::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
@media(max-width:990px){
    padding:30px 20px;
    gap:20px;
}
`
const LeftSide = styled.div`
display:flex;
flex-direction:column;
background:${colors.white};
justify-content:center;
align-items:center;
height:100%;
width:100%;
@media(max-width:990px){
width:100%;
}
`
const RightSide = styled.div`
display:flex;
flex-direction:column;
width:100%;

@media(max-width:990px){
  display:none;
}
`
const Logo = styled.div`
display:flex;
width:100%;
`
const Cover = styled.div`
position:fixed;
height:100%;
top:0;
right:0;

img{
    display:flex;
    height:100%;
    position:relative;
max-width:50vw;
}
@media(max-width:990px){
    display:none;
  }
`

const InsideWrapper = styled.div`
display:flex;
flex-direction:column;
margin-top:auto;
margin-bottom:auto;
width:100%;
overflow:auto;

`