import React, { useEffect, useReducer, useRef, useState } from 'react'
import styled from 'styled-components'
import Container1440 from '../Components/UIGeneric/Container1440'
import CustominputWithAction from '../Components/UIGeneric/CustominputWithAction'
import OutlineButton from '../Components/UIGeneric/OutlineButton'
import { colors } from '../Assets/colors/colors'
import { OUTLINE_GRADIENT, OUTLINE_NORMAL } from '../Constants/constants'
import { useUserContext } from '../Context/useUserContext'
import PrimaryButton from '../Components/UIGeneric/PrimaryButton'
import { ReactComponent as LogoutIcon } from "../Assets/icons/logoutIcon.svg"
import { ReactComponent as PencilIcon } from "../Assets/icons/pencilIcon.svg"
import { useNavigate, useOutletContext } from 'react-router-dom'
import { showErrorNotification, showGenericError, showSuccessNotification } from '../helper/Notifications'
import { auth } from '../firebase'
import { updateEmail } from "firebase/auth"
import LocalStorage from '../helper/LocalStorage'
import DeleteConfirmation from '../Components/UIGeneric/DeleteConfirmation'

const AccountPage = () => {

    const { user, logout, deleteUser, emailExistInFirebase, updateUser, fetchCurrentUser } = useUserContext();
    const { setIsLoading } = useOutletContext();
    const [name, setName] = useState(user.name);
    const [email, setEmail] = useState(user.email);
    const [phone, setPhone] = useState(user.phone_number);
    const nameRef = useRef();
    const phoneRef = useRef();
    const emailRef = useRef();
    const [nameReadOnly, setNameReadOnly] = useState(true);
    const [emailReadOnly, setEmailReadOnly] = useState(true);
    const [phoneReadOnly, setPhoneReadOnly] = useState(true);
    const [changes, setChanges] = useState(false);
    const [removeConfirmation, setRemoveConfirmation] = useState(false)
    const navigate = useNavigate();

    const handleNameChangeAction = () => {
        setNameReadOnly(false)
        nameRef.current.focus();
    }
    const handleEmailChangeAction = () => {
        setEmailReadOnly(false)
        emailRef.current.focus();
    }
    const handlePhoneChangeAction = () => {
        navigate('/phoneNumber')
    }

    const handleDeleteUser = () => {
        setIsLoading(true)
        deleteUser(
            () => { setIsLoading(false) },
            () => { setIsLoading(false) }
        )
    }
    const handleOpenConfirmDialog = () => {
        setRemoveConfirmation(true)
    }
    const handleCloseConfirmDialog = () => {
        setRemoveConfirmation(false)
    }

    const containerStyle = {
        width: "100%",
        minWidth: "200px",
    }

    const handleLogout = () => {
        logout();
    }

    const handleExistEmail = (email, onSuccess) => {
        emailExistInFirebase(
            email,
            (response) => {
                if (response.data) {
                    if (response.data.email_exists == false) {
                        onSuccess && onSuccess()
                        return response.data.email_exists
                    } else {
                        showErrorNotification("This email is already in use")
                        handleEmailChangeAction()
                        return response.data.email_exists
                    }
                }
            },
            (error) => {
                showGenericError();
            }
        )
    }

    const handleUpdateUser = (name, email) => {
        updateUser(
            name,
            email,
            (response) => {
                fetchCurrentUser((response) => {
                    // console.log(response)
                    showSuccessNotification("User updated")
                },
                    (error) => {
                        showGenericError();
                    })
            },
            (error) => {
                showGenericError();
            }
        )

    }

    const handleSaveChanges = () => {
        let newEmail = user.email;
        let newName = user.name;

        if (name != user.name) {
            newName = name
        }

        if (email != user.email) {
            newEmail = email
            handleExistEmail(email, () => {
                handleUpdateUser(newName, newEmail)
            })
        } else {
            handleUpdateUser(newName, newEmail)
        }

    }

    const checkChanges = () => {
        if (email != user.email || name != user.name) {
            setChanges(true)
        } else {
            setChanges(false)
        }
    }

    useEffect(() => {
        checkChanges()
    }, [email, name, user])

    return (
        <Container>
            <Container1440>
                <DeleteConfirmation
                    open={removeConfirmation}
                    handleClose={handleCloseConfirmDialog}
                    handleRemove={handleDeleteUser}
                />
                <Content>
                    <Title>
                        User information
                    </Title>
                    <SectionLine>
                        <CustominputWithAction
                            containerStyle={containerStyle}
                            title={"Name"}
                            buttonIcon={<PencilIcon />}
                            value={name}
                            name="name"
                            readOnly={nameReadOnly}
                            inputRef={nameRef}
                            onChange={e => setName(e.currentTarget.value)}
                            buttonAction={() => handleNameChangeAction()}
                        />
                        <CustominputWithAction
                            containerStyle={containerStyle}
                            title={"Mobile Phone"}
                            buttonIcon={<PencilIcon />}
                            value={phone}
                            name="phone"
                            readOnly={phoneReadOnly}
                            inputRef={phoneRef}
                            onChange={e => setPhone(e.currentTarget.value)}
                            buttonAction={() => handlePhoneChangeAction()}
                        />
                        <CustominputWithAction
                            containerStyle={containerStyle}
                            title={"Email address"}
                            buttonIcon={<PencilIcon />}
                            value={email}
                            name="email"
                            readOnly={emailReadOnly}
                            inputRef={emailRef}
                            onChange={e => setEmail(e.currentTarget.value)}
                            buttonAction={() => handleEmailChangeAction()}
                        />
                    </SectionLine>

                    <SectionLine style={{ marginLeft: 'auto', gap: "20px" }}>
                        {changes && <OutlineButton type={OUTLINE_NORMAL} text={"Save Changes"} style={{ minWidth: "190px" }} onClick={() => handleSaveChanges()} />}
                        <OutlineButton type={OUTLINE_NORMAL} text={"Delete Account"} color={colors.errorRed} style={{ minWidth: "190px" }} onClick={() => handleOpenConfirmDialog()} />
                        <PrimaryButton style={{ background: colors.darkColor, minWidth: "190px" }} icon={<LogoutIcon />} text={"Logout"} onClick={() => handleLogout()} />
                    </SectionLine>
                </Content>

            </Container1440>
        </Container>
    )
}

export default AccountPage

const Container = styled.div`

`
const Content = styled.div`
padding:30px 100px;
display:flex;
flex-direction:column;
gap:30px;
margin-top:20px;
width:100%;
@media(max-width:990px){
    padding:30px 20px;
}
`
const Title = styled.h1`
font-size:26px;
font-family:"Sailec-Medium";
font-weight:500;
line-height:38px;
`
const SectionLine = styled.div`
display:flex;
gap:75px;
@media(max-width:990px){
flex-direction:column;
align-items:center;
}
`
const SeparatorLine = styled.div`
height:2px;
background:${colors.headerBorderColor};
`