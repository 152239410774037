import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useUserContext } from "../Context/useUserContext";
import LocalStorage from "../helper/LocalStorage";
import LoaderComponent from "../Components/UIGeneric/LoaderComponent";
import { useEffect } from "react";


const ProtectedRoute = ({ children, redirectPath = "/login" }) => {

    const { fetchCurrentUser, userRef, user } = useUserContext();
    const location = useLocation();
    const navigate = useNavigate();


    const authToken = LocalStorage.getAuthenticationToken();


    useEffect(() => {
        if (authToken !== null) {
            fetchCurrentUser(
                function (response) {
                    // console.log(response, "response")
                    navigate({ redirectPath, search: location.search });
                },
                function (error) {
                    navigate("/login", { state: { backTo: `${location.pathname}` } })
                }
            );
        }
    }, [])

    if (user === undefined) {
        return (
            <LoaderComponent loading={true} />
        )
    }

    if (user === null) {
        return (
            <Navigate to={redirectPath} state={{ backTo: `${location.pathname}` }} />
        );
    }

    if (user) {
        return <>
            {userRef.current ? children : <LoaderComponent loading={true} />}

        </>;
    }
}

export default ProtectedRoute