import React from 'react'
import styled from 'styled-components'
import { colors } from '../../Assets/colors/colors'
import { ReactComponent as ErrorInfoIcon } from "../../Assets/icons/ErrorInfo.svg"

import CustomPhoneInput from '../UIGeneric/CustomPhoneInput'
import PrimaryButton from '../UIGeneric/PrimaryButton'
import InputWithTitleAndSubtitle from './InputWithTitleAndSubtitle'
import { useNavigate } from 'react-router-dom'
const RegisterWithPhoneNumber = ({
    containerStyle,
    phone,
    setPhone,
    setAgreeTerms,
    agreeTerms,
    name,
    setName,
    email,
    setEmail,
    showMoreDetails,
    phoneNumberError,
    continueDisabled,
    handleContinueButton,
    emailError,
    defaultCountry

}) => {
    const navigate = useNavigate()
    return (
        <Container style={containerStyle}>
            <PhoneWrapper style={{ marginTop: "10px" }}>
                <h1>What’s your phone number?</h1>
                <CustomPhoneInput
                    value={phone}
                    setValue={setPhone}
                    defaultCountry={defaultCountry}
                />
            </PhoneWrapper>
            {phoneNumberError &&
                <PhoneErrorWrapper>
                    <ErrorInfoIcon />
                    <div>
                        <p style={{ color: colors.errorRed }}>An account with this phone number already exists.</p>
                        <p>Try <span onClick={() => navigate("/login")}>Log In</span>  instead</p>
                    </div>

                </PhoneErrorWrapper>
            }
            {showMoreDetails &&
                <>
                    <InputWithTitleAndSubtitle
                        title={"What’s your name?"}
                        subtitle={"Just your first name will do."}
                        value={name}
                        name="name"
                        onChange={e => setName(e.currentTarget.value)}
                    />
                    <InputWithTitleAndSubtitle
                        title={"What’s your email address?"}
                        subtitle={"For Beurzbyte at Work, please use your company email address."}
                        value={email}
                        name="email"
                        onChange={e => setEmail(e.currentTarget.value)}
                    />
                    {emailError &&
                        <PhoneErrorWrapper>
                            <ErrorInfoIcon />
                            <div>
                                <p style={{ color: colors.errorRed }}>It seems that this email address is already in use.</p>
                                <p>Try <span onClick={() => navigate("/login")}>Log In</span>  instead</p>
                            </div>

                        </PhoneErrorWrapper>
                    }
                </>
            }

            <AgreeTerms>
                <input
                    type='checkbox'
                    value={agreeTerms}
                    checked={agreeTerms}
                    onChange={(e) => setAgreeTerms(e.currentTarget.checked)}
                />
                <p onClick={(e) => setAgreeTerms(!agreeTerms)} >I agree with the Beurzbyte <span onClick={() => window.open("https://beurzbyte.io/terms-and-conditions")}>Terms and Privacy Policy</span></p>
            </AgreeTerms>
            <ButtonWrapper >
                <PrimaryButton
                    onClick={handleContinueButton}
                    disabled={continueDisabled}
                    text={"Continue"}
                />
                <p>Already have an account? <span onClick={() => navigate("/login")}>Log in here</span>
                </p>
            </ButtonWrapper>

        </Container>
    )
}

export default RegisterWithPhoneNumber
const Container = styled.div`
display:flex;
flex-direction:column;
gap:40px;
width:100%;
margin-top:20px;
`
const TitleWrapper = styled.div`
display:flex;
flex-direction:column;
h1{
    font-size:24px;
    color:${colors.lightPurple};
    font-family:"Sailec-Medium";
    line-height:32px;
}
p{
    font-size:14px;
    color:${colors.darkColor};
    font-family:"Sailec-Regular";
    line-height:20px;
}
`
const PhoneWrapper = styled.div`
display:flex;
flex-direction:column;
gap:10px;
h1{
    font-size:16px;
    color:${colors.darkColor};
    font-family:"Sailec-Medium";
    line-height:24px;
}
`
const ButtonWrapper = styled.div`
display:flex;
flex-direction:column;
gap:20px;
p{
    font-size:14px;
    color:${colors.darkColor};
    font-family:"Sailec-Medium";
}
span{
    cursor:pointer;
    font-size:14px;
    color:${colors.darkColor};
    font-weight:600;
    font-family:"Sailec-Medium";
}
`
const AgreeTerms = styled.div`
display:flex;
gap:10px;
align-items:center;
input{
    cursor:pointer;
}
p{
      cursor:pointer;
    font-size:14px;
    color:${colors.darkColor};
    font-family:"Sailec-Medium";

    span{
        font-size:14px;
        font-weight:600;
        color:${colors.darkColor};
        font-family:"Sailec-Medium";
          text-decoration:underline;



    }
}
`
const PhoneErrorWrapper = styled.div`
display:flex;
align-items:start;
gap:10px;
p{
    color:${colors.darkColor};
    font-size:12px;
    font-family:"Sailec-Regular";
    font-weight:500;
    line-height:20px;
    span{
        font-weight:600;
        color:${colors.darkColor};
        cursor:pointer;
    }
}
`