import React from 'react'
import styled from 'styled-components'
import { ReactComponent as UserIcon } from "../../Assets/icons/UserIcon.svg"
import { colors } from '../../Assets/colors/colors'
import { useUserContext } from '../../Context/useUserContext'
const UserButton = ({ }) => {
    const { user } = useUserContext()
    return (
        <UserWrapper>
            <p>{user.name}</p>

        </UserWrapper>
    )
}

export default UserButton

const UserWrapper = styled.div`
display:flex;
align-items:center;
gap:10px;
p{
    color:${colors.darkColor};
    font-family:"Sailec-Medium";
    font-size:16px;
    line-height:24px;
    font-weight:600;
}

`