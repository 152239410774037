import React from 'react'
import styled from 'styled-components'
import { colors } from '../../Assets/colors/colors'


const PrimaryButton = ({ icon, text, ...props }) => {

    return (
        <CustomButton
            {...props}
        >
            {icon}
            {text}
        </CustomButton >
    )
}

export default PrimaryButton

const CustomButton = styled.button`
background:${colors.primaryGradient};
gap:10px;
display:flex;
align-items:center;
justify-content:center;
padding:11px 30px;
border:none;
color:${colors.white};
border-radius:7px;
font-family:"Sailec-Medium";
font-size:14px;
line-height:20px;
overflow:hidden;
cursor:pointer;
&:disabled{
    opacity:0.3;
    cursor:inherit;
}
`