import React from 'react'
import styled from 'styled-components'
import { colors } from '../../Assets/colors/colors'

const ProgressLine = ({ active }) => {
    return (
        <PLine active={active} />
    )
}
const PhaseCounter = ({ phase }) => {
    return (
        <Container>
            <Group active={phase > 0}>
                <ProgressLine active={phase > 0} />
                <p>Registration</p>
            </Group>
            <Group active={phase > 1}>
                <ProgressLine active={phase > 1} />
                <p>Verification</p>
            </Group>
            <Group active={phase > 2}>
                <ProgressLine active={phase > 2} />
                <p>Start Your Free Trial</p>
            </Group>
        </Container>
    )
}

export default PhaseCounter
const Container = styled.div`
display:flex;
jutify-content:center;
gap:30px;
@media(max-width:990px){
    gap:15px;
}
`
const PLine = styled.div`
display:flex;
min-width:120px;
width:100%;
border-radius:10px;
height:5px;
min-height:5px;
background:${props => props.active ? colors.validGreen : colors.headerBorderColor};
@media(max-width:990px){
    min-width:100px;
}
`
const Group = styled.div`
display:flex;
align-items:center;
flex-direction:column;
gap:15px;
width:100%;
p{
    color:${colors.darkColor};
    opacity:${props => props.active ? 1 : 0.3};
    font-size:11px;
    font-family:"Sailec-Regular";
}
`