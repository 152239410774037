import React, { useRef, useState, useEffect, } from 'react'
import styled from 'styled-components'
import Container1440 from '../Components/UIGeneric/Container1440'
import { colors } from '../Assets/colors/colors'
import LoginWithPhone from '../Components/LoginComponents/LoginWithPhone'
import { ReactComponent as LogoBig } from "../Assets/icons/beurzbyteLogoBig.svg"
import EnterCode from '../Components/LoginComponents/EnterCode'
import Ilustration12 from "../Assets/icons/loginIlustration_2x.webp"
import Ilustration3 from "../Assets/icons/ilustration4.webp"
import { auth } from '../firebase'
import { useUserContext } from '../Context/useUserContext'
import { RecaptchaVerifier } from 'firebase/auth'
import { useLocation, useNavigate } from 'react-router-dom'
import { showGenericError, showSuccessNotification } from '../helper/Notifications'
import LoaderComponent from '../Components/UIGeneric/LoaderComponent'
import { useDefaultCountry } from '../hooks/useGeolocationDb'
import { isMobile } from 'react-device-detect'

const LoginPage = () => {
    const location = useLocation();

    const { signInWithPhone, verifyOTP, fetchCurrentUser, phoneNumberExistInFirebase } = useUserContext();
    const navigate = useNavigate();
    const [phone, setPhone] = useState("");
    const [otp, setOtp] = useState("");
    const [wrongCode, setWrongCode] = useState();
    const [isLoading, setIsLoading] = useState(false);
    // const [defaultCountry, setDefaultCountry] = useState()
    const [defaultCountry] = useDefaultCountry()
    const [phase, setPhase] = useState(1);

    const confirmationRes = useRef();

    const initializeReCaptcha = () => {
        if (window.recaptchaVerifier == null) {
            window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
                'size': 'invisible',
                'callback': (response) => {
                    // reCAPTCHA solved, allow signInWithPhoneNumber.
                    //onSignInSubmit();
                },
            });
        }
    }

    const resetRechaptcha = () => {
        window.recaptchaVerifier.recaptcha.reset();
    }

    const reinitializeRecaptchaContainer = () => {
        window.recaptchaVerifier = null;
        let element = document.getElementById("recaptcha-container");
        let parent = element.parentElement;
        parent.removeChild(element)
        let newElement = document.createElement("div")
        newElement.setAttribute("id", "recaptcha-container")
        parent.appendChild(newElement);

    }


    const handlePhoneNumberExist = () => {
        phoneNumberExistInFirebase(
            phone,
            (response) => {
                if (response.data.exists === true) {
                    handleSignInWithPhone()
                } else {
                    navigate("/register", { state: { phone: phone } })
                }
            },
            (error) => {
                console.log(error);
                showGenericError();
            }
        )
    }

    const handleSignInWithPhone = () => {
        setIsLoading(true)
        if (window.recaptchaVerifier == null) {
            initializeReCaptcha();
        } else {
            resetRechaptcha();
        }

        signInWithPhone(
            phone,
            window.recaptchaVerifier,
            function (confirmationResult) {
                setIsLoading(false);
                confirmationRes.current = confirmationResult;
                setPhase(2);
                showSuccessNotification("Code sent")
            },
            function (error) {
                console.log(error, "err")
                showGenericError();
                setIsLoading(false)
            }
        )
    }
    const handleLoginBtn = () => {
        handlePhoneNumberExist();
    }

    const handleVerifyCode = (otp) => {
        setIsLoading(true)
        verifyOTP(
            confirmationRes.current,
            otp,
            function (response) {
                reinitializeRecaptchaContainer();
                setWrongCode(false);
                fetchCurrentUser(
                    (response) => {
                        if (location.state?.backTo) {
                            setIsLoading(false)
                            navigate(`${location.state?.backTo}`)
                        } else {
                            navigate(`/dashboard`)
                        }
                    },
                    (error) => {
                        if (error?.response?.status === 404) {
                            setIsLoading(false)
                            navigate("/register", { state: { phone: phone } })
                        }
                    }
                )

            },
            function (error) {
                setOtp("");
                setWrongCode(true);
                console.log(error);
                showGenericError();
                setIsLoading(false);

            }
        );
    };
    const handleResendOTP = () => {
        handleSignInWithPhone()
    }

    const autoCheckOtp = () => {
        if (otp.length !== 6) {
        } else {
            handleVerifyCode(otp);
        }
    };

    useEffect(() => {
        autoCheckOtp();
    }, [otp]);


    const phase1 = <LoginWithPhone
        phone={phone}
        setPhone={setPhone}
        defaultCountry={defaultCountry}
        handleLoginBtn={handleLoginBtn}
        containerStyle={{ width: "100%", }}

    />

    const phase2 = <EnterCode
        otp={otp}
        setOtp={setOtp}
        wrongCode={wrongCode}
        setWrongCode={setWrongCode}
        containerStyle={{ width: "100%" }}
        handleResendOTP={handleResendOTP}

    />

    const displayPhase = () => {
        switch (phase) {
            case 1:
                return phase1

            case 2:
                return phase2

            default:
                break;
        }
    }

    return (
        <LoaderComponent loading={isLoading}>
            <OutterWrapper>

                <Container1440 style={{ height: '100%' }}>
                    <div id="recaptcha-container"></div>
                    <Container>
                        <LeftSide>
                            <Content>
                                <Logo>
                                    <LogoBig />
                                </Logo>
                                <ContentWrapper>
                                    {displayPhase()}
                                </ContentWrapper>

                            </Content>
                        </LeftSide>
                        <RightSide>
                            {/* */}
                        </RightSide>
                    </Container>
                </Container1440>

                {!isMobile &&
                    <IllustrationWrapper>
                        {phase === 1 && <img src={Ilustration12} alt='' />}
                        {phase === 2 && <img src={Ilustration3} alt='' />}

                    </IllustrationWrapper>}



            </OutterWrapper>
        </LoaderComponent>
    )
}

export default LoginPage

const OutterWrapper = styled.div`
height:100%;
position:relative;
`
const Container = styled.div`
display:flex;
height:100%;
width:100%;
max-height:100svh;
`
const Content = styled.div`
padding:30px 90px;
display:flex;
flex-direction:column;
width:100%;
align-items:center;
height:100%;
gap:40px;
@media(max-width:990px){
    padding:30px 20px;
}
`
const LeftSide = styled.div`
display:flex;
flex-direction:column;
background:${colors.white};
justify-content:center;
align-items:center;
height:100%;
width:100%;
@media(max-width:990px){
width:100%;
}
`
const RightSide = styled.div`
display:flex;
width:100%;
height:100%;
@media(max-width:990px){
    display:none;
  }
`
const Logo = styled.div`
display:flex;
width:100%;
`
const ContentWrapper = styled.div`
display:flex;
align-items:center;
height:100%;
width:100%;

`
const IllustrationWrapper = styled.div`
position:fixed;
height:100%;
top:0;
right:0;

img{
    display:flex;
    height:100%;
    position:relative;
max-width:50vw;

}

@media(max-width:990px){
    display:none;
  }
`