import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { RouterProvider } from 'react-router-dom';
import { router } from './Router/router';
import UserContextProvider from './Context/useUserContext';
import { Toaster } from 'react-hot-toast';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <UserContextProvider>
    <Toaster />
    <RouterProvider router={router} />
  </UserContextProvider>

  // </React.StrictMode>
);
