import React from 'react'
import styled from 'styled-components'
import { colors } from '../../Assets/colors/colors'

const PriceItem = ({ gold, price, week, style, }) => {
    return (
        <PriceWrapper gold={gold} style={style} >
            {!isNaN(price) && <h1>${price}</h1>}
            {!isNaN(week) && <h2>${week} week</h2>}
        </PriceWrapper>
    )
}

export default PriceItem

const PriceWrapper = styled.div`
display:flex;
flex-direction:column;
align-items:center;
gap:5px;
h1{
font-family:"Sailec-Medium";
font-weight:500;
font-size:25px;
line-height:32px;
color:${props => props.gold ? colors.cardGoldColor : colors.darkColor};
}
h2{
    font-family:"Sailec-Regular";
    font-weight:500;
font-size:14px;
line-height:18px;
    color:${colors.darkColor};
}


`