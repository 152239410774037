import React from 'react'
import styled from 'styled-components'
import { colors } from '../../Assets/colors/colors'
import { OUTLINE_GRADIENT, OUTLINE_NORMAL } from '../../Constants/constants'
import { type } from '@testing-library/user-event/dist/type'


const OutlineButton = ({ type, color, text, ...props }) => {
    return (
        <>
            {type == OUTLINE_NORMAL &&

                < CustomOutline
                    {...props}
                    color={color}
                >
                    {text}
                </CustomOutline >
            }
            {
                type == OUTLINE_GRADIENT &&

                <CustomOutlineGradient
                    {...props}
                    color={color}
                >
                    <Bb>
                        {text}
                    </Bb>

                </CustomOutlineGradient>
            }


        </>
    )
}

export default OutlineButton
const CustomOutline = styled.button`
background:white;
padding:11px 40px;
border:1px solid ${props => props.color || colors.primaryPurple};
color:${props => props.color || colors.primaryPurple};
border-radius:7px;
font-family:"Sailec-Medium";
font-size:14px;
line-height:20px;
display:flex;
align-items:center;
justify-content:center;
&:disabled{
    opacity:0.3;
    cursor:inherit;
}
cursor:pointer;
`
const CustomOutlineGradient = styled.button`
padding:1px;
background:${colors.primaryGradient};
border:none;
border-radius:8px;
overflow:hidden;
display:flex;
align-items:center;
justify-content:center;
&:disabled{
    opacity:0.3;
    cursor:inherit;
}
cursor:pointer;
`
const Bb = styled.div`
display:flex;
align-items:center;
justify-content:center;
width:100%;
height:100%;
background:white;
color:${props => props.color || colors.primaryPurple};
padding:11px 40px;
border-radius:7px;
font-family:"Sailec-Medium";
font-size:14px;
line-height:20px;
`