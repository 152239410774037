import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Container1440 from '../Components/UIGeneric/Container1440'
import NoPlanPage from '../Components/PlanPageComponents/NoPlanPage'
import DownloadAppSection from '../Components/PlanPageComponents/DownloadAppSection'
import SubscribedPlan from '../Components/PlanPageComponents/SubscribedPlan'
import CancelSubscriptionModal from '../Components/PlanPageComponents/CancelSubscriptionModal'
import { useUserContext } from '../Context/useUserContext'
import { showGenericError } from '../helper/Notifications'
import { useOutletContext, useSearchParams } from 'react-router-dom'
import QrCodeModal from '../Components/GenericComponents/QrCodeModal'
import LoaderComponent from '../Components/UIGeneric/LoaderComponent'
import { pricesForCurrentUserAPICall } from '../Api/price/priceService'


const PlansPage = () => {
    const [showCancelModal, setShowCancelModal] = useState(false);
    const [showQrCode, setShowQrCode] = useState(false);
    const { getUserSubscription, addUserSubscription, cancelUserSubscription } = useUserContext();
    const { setIsLoading } = useOutletContext()
    const [subscription, setSubscription] = useState(undefined);
    const [expireAt, setExpireAt] = useState("")

    const [searchParams, setSearchParams] = useSearchParams();
    const [prices, setPrices] = useState(null);


    const handleGetActiveSubscription = () => {
        setIsLoading(true)
        getUserSubscription(
            (response) => {
                // console.log(response, 'active SUb')
                setIsLoading(false)
                setSubscription(response.data);
                setExpireAt(response.data.stripe_subscription.current_period_end)
            },
            (error) => {

                if (error.response.data.message === 'No active subscriptions') {
                    setSubscription(null)
                    setIsLoading(false)
                } else {
                    showGenericError()
                }

            }
        )
    }

    const handleCancelUserSubscription = () => {
        setIsLoading(true)
        cancelUserSubscription(
            (response) => {
                setTimeout(() => {
                    handleGetActiveSubscription()
                }, 5000)

            },
            (error) => {
                showGenericError()
            }
        )
    }

    const handleCancelSubscriptionBtn = () => {
        handleCancelUserSubscription();
        setShowCancelModal(false)

    }
    const handleCancelSubscriptionButton = () => {
        setShowCancelModal(true)
    }
    const getCurrentUserPrices = () => {
        pricesForCurrentUserAPICall(
            (response) => {
                setPrices(response.data)
                console.log(response)
            },
            (error) => {
                console.log(error)
            }
        )
    }


    useEffect(() => {
        getCurrentUserPrices();

        let redirected = searchParams.get("from_stripe");

        if (redirected) {
            setIsLoading(true);
            setTimeout(() => {
                handleGetActiveSubscription();
            }, 5000)

            setSearchParams((prev) => {
                prev.delete("from_stripe");
                return prev
            })

        } else {
            handleGetActiveSubscription()
        }

    }, [])



    return (
        <Container>

            <Container1440>
                <Content>
                    {subscription &&
                        <SubscribedPlan
                            handleCancelSubscriptionButton={handleCancelSubscriptionButton}
                            subscription={subscription}
                            prices={prices}

                        />}
                    {subscription === undefined &&
                        <div style={{ minHeight: "450px", display: "flex" }}></div>
                    }
                    {subscription === null &&
                        <NoPlanPage
                            prices={prices}
                        />}

                    <DownloadAppSection
                        setShowQrCode={setShowQrCode}
                    />

                </Content>
            </Container1440>

            {showCancelModal &&
                <CancelSubscriptionModal
                    setIsModalOpen={setShowCancelModal}
                    handleCancelSubscriptionBtn={handleCancelSubscriptionBtn}
                    expireAt={expireAt}
                />}
            {showQrCode &&
                <QrCodeModal
                    setIsModalOpen={setShowQrCode}
                />
            }

        </Container>
    )
}

export default PlansPage

const Container = styled.div`
height:100%;
position:relative;
`
const Content = styled.div`
display:flex;
flex-direction:column;
width:100%;

`